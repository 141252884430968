import React from 'react';
import Button from '@material-tailwind/react/components/Button';
import Typography from '@material-tailwind/react/components/Typography';
import { useDispatch } from 'react-redux';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { sendOtp } from '../authActions';
import { SelectFromData } from '../../../components/controls/select/SelectFromData';
import { PhonePicker } from '../../../components/controls/PhonePicker';
import { PHONE_REG_EXP } from '../../../utilities/constants';
import { useGetCountriesQuery } from '../../..//lib/services/countries';
import { Spinner } from '../../../components/controls/svg/Spinner';
import { useAuthentication } from 'hooks/useAuthentication';
import isEmpty from 'utilities/isEmpty';

export function SendOtpForm(props) {
  const { isAuthenticated, user, phoneNumberConfirmed, userHash } = useAuthentication();
  const dispatch = useDispatch();
  // const countries = useSelector((state) => state.home.countries);
  const validationSchema = yup.object().shape({
    countryRegionCode: yup.string().required('Country / region is required'),
    mobileNumber: yup
      .string()
      .nullable()
      .required('Phone number is required')
      .matches(PHONE_REG_EXP, 'Phone number is not valid'),
  });

  const handleFormikSubmit = (values, { setSubmitting }) => {
    dispatch(sendOtp({ values: values, setSubmitting: setSubmitting }));
  };

  const { data: countries, isFetching, isLoading } = useGetCountriesQuery([]);

  if (isFetching || isLoading) {
    return (
      <div className="text-center flex">
        <Spinner className="m-auto" />
      </div>
    );
  }

  const countryOptions = countries.map((country, index) => {
    return {
      value: country.cca2,
      label: `${country.commonName} ${country.flag} (+${country.callingCode})`,
    };
  });

  const sendDisabled = (values) => {
    if (isAuthenticated && !isEmpty(user.phoneNumber) && !isEmpty(user.contactCountryCode) && values.mobileNumber === user.phoneNumber && values.countryRegionCode === user.contactCountryCode && phoneNumberConfirmed) {
      return true
    }
    return false;
  };

  return (
    <Formik
      onSubmit={handleFormikSubmit}
      enableReinitialize
      initialValues={{
        userHash: userHash,
        countryRegionCode: !isEmpty(user?.contactCountryCode) ? user?.contactCountryCode : 'ZA',
        mobileNumber: !isEmpty(user?.phoneNumber) ? user?.phoneNumber : '',
      }}
      validationSchema={validationSchema}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        setValues,
        setFieldValue,
        isSubmitting,
        setFieldTouched,
      }) => (
        <form onSubmit={handleSubmit} id="verifyOtpForm" noValidate>
          <div className="flex flex-col gap-3 mt-1 pt-2">
            <div className="text-center mb-3">
              <Typography className="text-sm mb-2" variant="paragraph">
                You&apos;re helping to make VenueConfim a safer place by verifying
                your mobile number.
              </Typography>
              <Typography className="text-sm" variant="paragraph">
                Please enter it below and we&apos;ll send you an SMS with an OTP
                code.
              </Typography>
            </div>
            <SelectFromData
              id="countryRegionCode"
              name="countryRegionCode"
              options={countryOptions}
              onChange={(value) =>
                setFieldValue('countryRegionCode', value.value)
              }
              onBlur={() => setFieldTouched('countryRegionCode', true)}
              value={countryOptions.filter(
                (option) => option.value === values.countryRegionCode,
              )}
              error={errors.countryRegionCode && touched.countryRegionCode}
              success={!errors.countryRegionCode && touched.countryRegionCode}
            />
            <ErrorMessage name="countryRegionCode">
              {(msg) => <div className="text-error text-xs">{msg}</div>}
            </ErrorMessage>
            <PhonePicker
              pattern="[0-9]*"
              inputMode="numeric"
              id="mobileNumber"
              name="mobileNumber"
              value={values.mobileNumber}
              onChange={(e) => setFieldValue('mobileNumber', e.target.value)}
              onBlur={() => setFieldTouched('mobileNumber', true)}
              error={errors.mobileNumber && touched.mobileNumber}
              success={!errors.mobileNumber && touched.mobileNumber}
            />
            <ErrorMessage name="mobileNumber">
              {(msg) => <div className="text-error text-xs">{msg}</div>}
            </ErrorMessage>

            <div className="pt-0 mt-2">
              <Button
                size="sm"
                className="btn btn-success bg-success btn-md mt-2"
                disabled={isSubmitting || sendDisabled(values)}
                type="submit"
                fullWidth>
                Send OTP
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
