import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import Alert from "@material-tailwind/react/components/Alert";
import Typography from "@material-tailwind/react/components/Typography";
import { useSelector } from "react-redux";
import isEmpty from "utilities/isEmpty"

export function AuthErrorAlert(props) {
    const error = useSelector((state) => state.auth.error);
    return !isEmpty(error) && !isEmpty(error.message) && (
        <Alert
            icon={<ExclamationTriangleIcon className="h-7 w-7" />}
            className="bg-error mb-3 !max-w-screen">
            <Typography className="font-bold">{error.message}</Typography>
            {!isEmpty(error.errors) && (
                <ul className="mt-2 list-inside list-disc font-bold text-xs">
                    {error.errors.map((errorMessage, i) => (
                        <li key={`error_${i}`}>{errorMessage.message}</li>
                    ))}
                </ul>
            )}
        </Alert>
    )
}