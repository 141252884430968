import React, { useState } from "react";
import Input from "@material-tailwind/react/components/Input";
import { useDispatch } from 'react-redux'
import {
  EnvelopeIcon,
} from "@heroicons/react/24/solid";
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { resetPassword } from '../authActions'
import { setFormIndex } from '../authSlice';
import { AuthForms } from "utilities/constants";


export function ForgotPassword(props) {
  const [resetPasswordComplete, setResetPasswordComplete] = useState(false);
  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    email: yup.string().required("Email is required").email("Email is the incorrect format").matches(/@[^.]*\./, "Email is the incorrect format"),
  });

  const handleFormikSubmit = (values, { setSubmitting }) => {
    dispatch(resetPassword({
      values: values, setSubmitting: setSubmitting, successCallback: () => {
        setResetPasswordComplete(true);
      }
    }))
  }

  return (
    !resetPasswordComplete ?
      <Formik
        onSubmit={handleFormikSubmit}
        enableReinitialize
        initialValues={{
          email: ''
        }}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, setFieldValue, isSubmitting }) => (
          <form onSubmit={handleSubmit} id="loginForm" noValidate>
            <div className="flex flex-col gap-2  pt-4 mt-1">
              <p className=" mb-4">Enter the email associated with your account.</p>
              <Input color="green" label="Email" size="lg"
                icon={<EnvelopeIcon className="h-5 w-5 text-base-content/100" />} id="email" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} error={errors.email && touched.email}
                success={!errors.email && touched.email} className="bg-base-100 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10" />
              <ErrorMessage name="email">{msg => <div className="text-error text-xs">{msg}</div>}</ErrorMessage>
              <div className="pt-0 mt-4 space-y-2">
                <button className="btn btn-error btn-md btn-block" type="submit" disabled={isSubmitting}>
                  Reset
                </button>
                <button className="btn btn-ghost btn-md btn-block" type="button" disabled={isSubmitting} onClick={() => dispatch(setFormIndex(AuthForms.LOGIN))}>
                  Back to Sign In
                </button>
              </div>
            </div>
          </form>)}
      </Formik>
      : <>
        <p className="text-center mb-4">An email containing a link to reset your password has been sent to the email address provided.</p>
        <button className="btn btn-ghost btn-outline btn-md btn-block" type="button" onClick={() => dispatch(setFormIndex(AuthForms.LOGIN))}>
          Back to Sign In
        </button>
      </>

  );
}