import React, { Suspense, useState } from 'react';
import { Spinner } from './svg/Spinner';

export function LoaderWrapper(props) {
  const loading = props.loading ?? true;
  const visible = props.visible ?? true;
  const className = props.className ?? "";

  if (!visible)
    return null;

  return (
    <>
      {loading && <div className={`flex items-center justify-center min-h-[200px] ${className}`}>
        <Spinner />
      </div>}
      <Suspense>
        <div className={`${loading && 'hidden'}`}>{props.children}</div>
      </Suspense>
    </>

  );
}
