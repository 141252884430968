import React, { useEffect, useState } from "react";
import Typography from "@material-tailwind/react/components/Typography";
import { useDispatch, useSelector } from 'react-redux'
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { verifyOtp } from '../authActions'
import { AuthForms, PIN_REG_EXP } from "../../../utilities/constants";
import { PinInput } from "../../../components/controls/PinInput";
import { setFormIndex } from "../authSlice";
import isEmpty from "utilities/isEmpty";
import { useAuthentication } from "hooks/useAuthentication";


export function VerifyOtpForm(props) {
  const { userHash } = useAuthentication();
  const otpTimeToWait = useSelector((state) => state.auth.otpTimeToWait);
  const dispatch = useDispatch();

  const [timeLeft, setTimeLeft] = useState(otpTimeToWait ?? 0);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const validationSchema = yup.object().shape({
    otp: yup.string().nullable().required("OTP is required").matches(PIN_REG_EXP, 'OTP is not valid')
  });

  const handleFormikSubmit = (values, { setSubmitting }) => {
    dispatch(verifyOtp({ values: values, setSubmitting: setSubmitting }))
  }

  const handleSendOtp = () => {
    dispatch(setFormIndex(AuthForms.SEND_OTP));
  }


  return (
    <Formik
      onSubmit={handleFormikSubmit}
      enableReinitialize
      initialValues={{
        userHash: userHash,
        otp: "",
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, setFieldValue, isSubmitting, setFieldTouched }) => (
        <form onSubmit={handleSubmit} id="loginForm" noValidate>
          <div className="flex flex-col gap-3 mt-1 pt-2">
            <div className="text-center mb-3">
              <Typography className="text-sm mb-2" variant="paragraph" >
                Check your SMS inbox, we sent you an OTP.
              </Typography>
              <Typography className="text-sm mb-2" variant="paragraph" >
                Enter your 4 digit OTP below.
              </Typography>
            </div>
            <PinInput pattern="[0-9]*" inputMode="numeric" id="otp" name="otp"
              onChange={(e) => setFieldValue("otp", e.target.value)}
              onBlur={() => setFieldTouched("otp", true)}
              error={errors.otp && touched.otp}
              success={!errors.otp && touched.otp} />
            <ErrorMessage name="otp">{msg => <div className="text-error text-xs">{msg}</div>}</ErrorMessage>
            <div className="pt-0 mt-2">
              <button className="btn btn-success  btn-block btn-md mt-2" disabled={isSubmitting} type="submit" >
                Verify OTP
              </button>
              <button className="btn btn-outline  btn-block  btn-md mt-2" disabled={isSubmitting || timeLeft > 0} onClick={handleSendOtp} type="button" >
                Resend OTP {!isEmpty(timeLeft) && timeLeft > 0 && `in ${timeLeft} seconds`}
              </button>
            </div>
          </div>
        </form>)}
    </Formik>

  );
}