import React from "react";
import Button from "@material-tailwind/react/components/Button";
import Input from "@material-tailwind/react/components/Input";
import { useDispatch } from 'react-redux'
import {
  EnvelopeIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { userLogin } from '../authActions'
import { setFormIndex } from '../authSlice';
import { AuthForms } from "utilities/constants";


export function SignInForm(props) {
  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    email: yup.string().required("Email is required").email("Email is the incorrect format").matches(/@[^.]*\./, "Email is the incorrect format"),
    password: yup.string().required("Password is required"),
  });

  const handleFormikSubmit = (values, { setSubmitting }) => {
    dispatch(userLogin({ values: values, setSubmitting: setSubmitting }))
  }

  const handleForgotPassword = () => {
    dispatch(setFormIndex(AuthForms.FORGOT_PASSWORD));
  }

  return (
    <Formik

      onSubmit={handleFormikSubmit}
      enableReinitialize
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, setFieldValue, isSubmitting }) => (
        <form onSubmit={handleSubmit} id="loginForm" noValidate>
          <div className="flex flex-col gap-2 mt-1 pt-4">
            {/* <Button size="sm" className="group relative overflow-hidden pr-[72px] btn btn-md !border-light-blue-500 flex items-center gap-3 p-0 mt-3 bg-light-blue-500 hover:bg-light-blue-600" >
              Sign in with Google
              <span className="absolute right-0 grid h-full w-12 place-items-center bg-light-blue-600 transition-colors group-hover:bg-light-blue-700">
                <GoogleIcon className="h-[35px] w-[35px]" />
              </span>
            </Button>

            <div className="text-center mb-3">
              <Typography className="-mb-2" variant="h6" >
                OR
              </Typography>
            </div> */}
            <Input color="green" label="Email" size="lg"
              icon={<EnvelopeIcon className="h-5 w-5 text-base-content/100" />} id="email" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} error={errors.email && touched.email}
              success={!errors.email && touched.email} className="bg-base-100 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10" />
            <ErrorMessage name="email">{msg => <div className="text-error text-xs">{msg}</div>}</ErrorMessage>
            <Input color="green" type="password" label="Password" size="lg" icon={<LockClosedIcon className="h-5 w-5 text-base-content/100" />} id="password" name="password" onChange={handleChange} onBlur={handleBlur} value={values.password}
              error={errors.password && touched.password} success={!errors.password && touched.password} className="bg-base-100 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10" />
            <ErrorMessage name="password">{msg => <div className="text-error text-xs">{msg}</div>}</ErrorMessage>
            <div className="-mr-2.5 text-right">
              <button type="button" className="btn btn-sm btn-ghost" onClick={handleForgotPassword}>Forgot password</button>
            </div>
            <div className="pt-0 mt-2">
              <Button size="sm" className="btn btn-neutral bg-neutral btn-md" fullWidth type="submit" disabled={isSubmitting}>
                Sign In
              </Button>
              <Button size="sm" className="btn btn-success bg-success btn-md mt-2" onClick={() => dispatch(setFormIndex(1))} fullWidth>
                Sign Up with Email
              </Button>
            </div>
          </div>
        </form>)}
    </Formik>

  );
}