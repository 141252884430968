import React from "react";
import Input from "@material-tailwind/react/components/Input";
import { useDispatch } from 'react-redux'
import {
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { confirmResetPassword } from '../authActions'
import { setFormIndex } from '../authSlice';
import { AuthForms } from "utilities/constants";
import { useSearchParams } from "next/navigation";
import { alertSuccess } from "components/controls/functions/alerts";


export function ResetPassword() {

  const dispatch = useDispatch();
  const searchParams = useSearchParams();

  const validationSchema = yup.object().shape({
    token: yup.string().required("Token is required"),
    user: yup.string().required("User is required"),
    password: yup.string().required("Password is required").min(6, "Password must be at least 6 characters.")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\W)[A-Za-z\d\W]{6,}/, {
        message: 'Passwords must have at least one non-alphanumeric, one lowercase and one uppercase character.'
      })
    ,
    retypePassword: yup.string().required("Re-type password is required").oneOf([yup.ref('password'), null], 'Passwords must match')
  });

  const handleFormikSubmit = (values, { setSubmitting }) => {
    dispatch(confirmResetPassword({
      values: values, setSubmitting: setSubmitting, successCallback: () => {
        alertSuccess("Your password has been reset and you have been logged in.")
      }
    }))
  }

  return (
    <Formik
      onSubmit={handleFormikSubmit}
      enableReinitialize
      initialValues={{
        token: searchParams.get('token'),
        user: searchParams.get('user'),
        password: '',
        retypePassword: ''
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, setFieldValue, isSubmitting }) => (
        <form onSubmit={handleSubmit} id="loginForm" noValidate>
          <div className="flex flex-col gap-2  pt-4 mt-1">
            <p className=" mb-4 text-center">Enter your new password.</p>
            <Input color="green" type="password" label="Password" size="lg" icon={<LockClosedIcon className="h-5 w-5 text-base-content/100" />} id="password" name="password" onChange={handleChange} onBlur={handleBlur} value={values.password}
              error={errors.password && touched.password} success={!errors.password && touched.password} className="bg-base-100 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10" />
            <ErrorMessage name="password">{msg => <div className="text-error text-xs">{msg}</div>}</ErrorMessage>
            <Input color="green" type="password" label="Re-type password" size="lg" icon={<LockClosedIcon className="h-5 w-5 text-base-content/100" />} id="retypePassword" name="retypePassword" onChange={handleChange} onBlur={handleBlur} value={values.retypePassword}
              error={errors.retypePassword && touched.retypePassword} success={!errors.retypePassword && touched.retypePassword} className="bg-base-100 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10" />
            <ErrorMessage name="retypePassword">{msg => <div className="text-error text-xs">{msg}</div>}</ErrorMessage>
            <div className="pt-0 mt-4 space-y-2">
              <button className="btn btn-success btn-md btn-block" type="submit" disabled={isSubmitting}>
                Reset
              </button>
              <button className="btn btn-ghost btn-md btn-block" type="button" disabled={isSubmitting} onClick={() => dispatch(setFormIndex(AuthForms.LOGIN))}>
                Back to Sign In
              </button>
            </div>
          </div>
        </form>)}
    </Formik>
  );
}